import React, { useContext, useEffect, useState } from 'react';
import { Row, Button, Card, CardBody, CardFooter, CardHeader, Container } from 'shards-react';
import { useParams } from 'react-router-dom';
import { BASE_URL, requestHeader, useFetch } from '../../../hooks/useFetch';
import { CircularLoader } from '../../../components/common/loader/CircularLoader';
import { VariablesContext } from '../../../context/VariablesContext';
import FormValidation from './FormValidation';
import TableValidation from '../linelists/TableValidation';
import { Link, Redirect } from 'react-router-dom';
import '../../../assets/styles/ValidatiinForm.css';
import axios from 'axios';
import { AuthContext } from '../../../context/AuthProvider';
import { LinearProgress } from '@mui/material';
import Alert from '../../../components/common/alert/alertModal/Alert';
import { successMsg, ToastNotification } from '../../../components/common/notification/ToastNotification';
import { LoadingButton } from '@mui/lab';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

const ValidateReport = () => {

  const { id } = useParams()
  const { variables } = useContext(VariablesContext)
  const [open, setOpen] = useState(false);
  const [allVariables, setallVariables] = useState([])
  const [dataElements, setdataElements] = useState([])
  const [valuesDataElements, setvaluesDataElements] = useState([])
  const [disableFields, setdisableFields] = useState(true)
  const { auth } = useContext(AuthContext)
  const [loader, setLoader] = useState(false)
  const [loaderSave, setLoaderSave] = useState(false)
  const [message, setmessage] = useState(false)
  const [error, seterror] = useState(false)
  const [openPopUp, setopenPopUp] = useState(false)
  const [popUrl, setpopUrl] = useState("")


  const { data, isFetching } = useFetch(`/api/covid/immunization/certificate/solicitations/${id}`)

  const postData = async () => {
    setLoader(true)
    data.status = 'PRINTED'
    await axios.put(`${BASE_URL}/api/covid/immunization/certificate/solicitations`, data, requestHeader())
      .then(r => {
        if (r.status === 200) {

          const body = {
            "trackedEntityInstance": r.data.patient.teiId,
            "userId": auth.id,
            "username": auth.userCredentials.username,
            "provider": "WEB_APP",
            "printDate": new Date().toISOString().substring(0, 10) + ' ' + new Date().toISOString().substring(11, 19),
            "location": data.location,
            "locationName": null,
            "solicitationId": data.id
          }

          axios.post(`${BASE_URL}/api/covid/immunization/certificate/print`, body, requestHeader())
            .catch(err => {
              console.log(err)
            })

          setLoader(false)

          let URL = `/certificate/print/${r.data.patient.teiId}/${auth.id}`

          setpopUrl(URL)

          setopenPopUp(true)
        }
      })
      .catch(e => {
        setmessage("Erro ao imprimir,tente novamente ou informe o utente que deve solicitar a segunda via")
        seterror(true)
        setLoader(false)
      })
  }

  useEffect(() => {
    if (data && variables) {
      getVariables()
    }
  }, [variables, data, loaderSave])


  function getVariables() {
    if (variables && variables.hasOwnProperty("programs")) {
      let variab = []
      for (const attr of variables.programs[0].programTrackedEntityAttributes || []) {
        // console.log(attr.attributeValue)
        if (attr.hasOwnProperty("options")) {
          variab.push({ name: attr.name, id: attr.id, type: attr.valueType, options: attr.options, valueType: "LIST", value: data.patient.hasOwnProperty(attr.attributeValue) ? data.patient[attr.attributeValue] : "", attributeName: attr.attributeValue })
        } else {
          if (attr.attributeValue.split(".").length > 1) {
            variab.push({ name: attr.name, id: attr.id, type: attr.valueType, valueType: attr.valueType, value: data.patient[attr.attributeValue.split(".")[0]][0].hasOwnProperty(attr.attributeValue.split(".")[1]) ? data.patient[attr.attributeValue.split(".")[0]][0][attr.attributeValue.split(".")[1]] : "", attributeName: attr.attributeValue })
          } else
            // console.log(data.patient[attr.attributeValue.split(".")[0]][0][attr.attributeValue.split(".")[1]])
            variab.push({ name: attr.name, id: attr.id, type: attr.valueType === 'AGE' ? 'date' : attr.valueType, valueType: attr.valueType, value: data.patient.hasOwnProperty(attr.attributeValue) ? data.patient[attr.attributeValue] : "", attributeName: attr.attributeValue })

        }
      }

      setallVariables(variab)

      let valuesEVent = []
      for (const imun of data.patient.immunizations) {
        let localValues = []
        for (const dataElement of variables.programs[0].programStages[0].programStageDataElements) {
          if (dataElement.hasOwnProperty("options")) {
            localValues.push({ name: dataElement.name, reference: dataElement.id, type: dataElement.valueType, valueType: "LIST", options: dataElement.options, value: imun[dataElement.attributeValue], attributeName: dataElement.attributeValue })
          } else
            if (dataElement.id) {
              if (imun[dataElement.attributeValue])
                localValues.push({ name: dataElement.name, reference: dataElement.id, type: dataElement.valueType, valueType: dataElement.valueType, value: imun[dataElement.attributeValue].split(" ")[0], attributeName: dataElement.attributeValue })

            } else
              localValues.push({ name: dataElement.name, reference: dataElement.id, type: dataElement.valueType, valueType: dataElement.valueType, value: imun[dataElement.attributeValue], attributeName: dataElement.attributeValue })
        }
        valuesEVent.push(localValues)
      }
      setvaluesDataElements(valuesEVent)


      let variaDataElement = []
      for (const dataElement of variables.programs[0].programStages[0].programStageDataElements) {
        if (dataElement.hasOwnProperty("options")) {
          variaDataElement.push({ name: dataElement.name, reference: dataElement.id, type: dataElement.valueType, valueType: "LIST", options: dataElement.options })
        } else
          variaDataElement.push({ name: dataElement.name, reference: dataElement.id, type: dataElement.valueType, valueType: dataElement.valueType })
      }
      setdataElements(variaDataElement)
    }
  }

  const postForm = async () => {

    if (!disableFields) {
      setLoaderSave(true)
      await axios.post(`${BASE_URL}/api/covid/immunization/certificate/solicitations`, data, requestHeader())
        .then((e) => {
          console.log(e)
          if (e.data.status === 201) {
            setLoaderSave(false)
            successMsg("Dados do utente actualizados com sucesso")
            setOpen(true)
          }
        })
        .catch(e => {
          console.log(e)
        })
      setdisableFields(!disableFields)
    } else setdisableFields(!disableFields)
  }


  if (openPopUp) {
    return <Redirect
      to={popUrl}
    />
  }

  if (error) {
    return <Alert
      handleClose={seterror}
      message={message}
      show={error}
    />
  }

  return (
    <Container className='p-4 '>
      {isFetching ? (
        <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
          <CircularLoader size={30} />
        </div>
      ) : (
        <div className='d-flex'>

          <Row >
            <Card small className="mb-4 ">
              {loaderSave && <LinearProgress />}
              <CardHeader className="border-bottom " >
                <h6 className="m-0 headerBackground">DADOS PESSOAIS</h6>
              </CardHeader>
              <CardBody>
                <FormValidation originValues={data.patient} setallVariables={setallVariables} data={allVariables} disabled={disableFields} />
              </CardBody>
            </Card>
            <Card small className="mb-4 col-lg-12 ">
              <CardHeader className="border-bottom " >
                <h6 className="m-0 headerBackground">VACINAS</h6>
              </CardHeader>
              <CardBody>
                <TableValidation data={dataElements} originValues={data.patient} setvaluesDataElements={setvaluesDataElements} values={valuesDataElements} disabled={disableFields} />
              </CardBody>
            </Card>
            <Card small className="mb-4 col-lg-12  ">
              <CardHeader className="border-bottom " >
                <h6 className="m-0 headerBackground">DOCUMENTOS DE SUPORTE</h6>
              </CardHeader>
              <CardBody>
                <div className=' d-flex justify-content-center'>
                  {data.documents.map((image, index) => (
                    <img className='p-2' alt="not found" width={"50%"} src={image.path} />
                  ))}
                </div>
              </CardBody>
              <CardFooter className='d-flex justify-content-end'>
                <Button className="mr-auto" disabled={loaderSave} onClick={() => postForm()}>{loaderSave ? "Processando" : !disableFields ? "Salvar dados" : "Editar Campos"}</Button>
                <Link to={`/covid/reports/requested`}><Button className="mr-2" theme="danger">Cancelar</Button></Link>
                <LoadingButton loading={loader} style={{textTransform:'capitalize',fontSize:'.75rem'}} disabled={loader} onClick={() => postData()} color="primary" variant="contained" loadingPosition="start" startIcon={<LocalPrintshopIcon />} className="mr-2">Imprimir</LoadingButton>
              </CardFooter>
            </Card>

            {open && <ToastNotification />}

          </Row>
          <br></br>
          <Row >

          </Row>
        </div>

      )}
    </Container>
  )
}

export { ValidateReport }