import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/moz.png";
import "../../../assets/styles/navbar.css";
import { defaultRoutes } from "../../common";

function NavabarPublic(props) {
  // console.log(history.location.pathname.split("/")[2])

  return (
    <React.Fragment>
      <nav
        id="header"
        class="navbar navbar-expand-md fixed-top navbar-light bg-light"
      >
        <div class="container-fluid align-items-center">
          <div className="ms-2 logo me-auto d-flex">
            <Link to="/">
              <img width={40} src={logo} alt="" className="img-fluid" />
            </Link>
            <h1 className="text-light">
              <Link to="/">
                <span>Ministério da Saúde</span>
              </Link>
            </h1>
          </div>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="me-4 ">
            <ul
              class="navbar-nav mb-2 mb-lg-0 collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <span className="d-flex">
                <li className="nav-item d-flex">
                  {/*  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Individual
                                            </a> */}
                  {/* <Link className="nav-link" to='/'>
                    Meu certificado
                  </Link> */}
                  {/*  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <li><Link className="dropdown-item" to="/individual/pre-registo">Pré-Registo</Link></li>
                                                <li><Link style={{ paddingRight: 14 }} className="dropdown-item" to="/individual/login" >{t("Login")}</Link></li>
                                            </ul> */}
                </li>
                <li className="nav-item d-flex">
                  <Link className="nav-link" to={defaultRoutes[2]}>
                    Acesso Institucional
                  </Link>
                </li>
              </span>
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}
export default NavabarPublic;
