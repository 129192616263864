const smallStats = () => [
    {
        label: "Requisitados",
        value: "0",
        percentage: "0.00%",
        increase: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
            {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(0, 184, 216, 0.1)",
                borderColor: "rgb(0, 184, 216)",
                data: [0, 0, 0, 0, 0, 0, 0]
            }
        ]
    },
    {
        label: "Validados",
        value: "0",
        percentage: "0.00%",
        increase: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
            {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(0, 184, 216, 0.1)",
                borderColor: "rgb(0, 184, 216)",
                data: [0, 0, 0, 0, 0, 0, 0]
            }
        ]
    },
    {
        label: "Emitidos",
        value: "0",
        percentage: "0.00%",
        increase: false,
        decrease: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
            {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(0, 184, 216, 0.1)",
                borderColor: "rgb(0, 184, 216)",
                data: [0, 0, 0, 0, 0, 0, 0]
            }
        ]
    }, {
        label: "Autenticados",
        value: "0",
        percentage: "0.00%",
        increase: false,
        decrease: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
            {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(0, 184, 216, 0.1)",
                borderColor: "rgb(0, 184, 216)",
                data: [0, 0, 0, 0, 0, 0, 0]
            }
        ]
    },
    {
        label: "Entregues",
        value: "0",
        percentage: "0.00%",
        increase: false,
        decrease: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
            {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(0, 184, 216, 0.1)",
                borderColor: "rgb(0, 184, 216)",
                data: [0, 0, 0, 0, 0, 0, 0]
            }
        ]
    }
]

export {smallStats}