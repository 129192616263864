import React from 'react'
import "./Stepper.css";

function Stepper({ steppers, changeStepper}) {

   const stepChangerVerify = (step,i) => {
       if(step.reacheable===true){
        changeStepper(i)
       }
   }

    return (
        <div className="md-stepper-horizontal primary">
            {steppers.map((x, i) =>
                <div className={`md-step  ${x.active ? "active" : ""}`}>
                    <div className="md-step-circle" onClick={() => stepChangerVerify(x,i)}><span>{i + 1}</span></div>
                    <div className="md-step-title" >{x.name}</div>
                    <div className="md-step-bar-left"></div>
                    <div className="md-step-bar-right"></div>
                </div>
            )}
        </div>

    )
}

export default Stepper