import axios from 'axios'
import React, { useContext, useState } from 'react'
import { Button, Container, Form, Row } from 'shards-react'
import { BASE_URL } from '../../../hooks/useFetch'
import { base64Enconder } from '../../../service/base64Enconder'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import { AuthContext } from '../../../context/AuthProvider'
import mozambique from '../../../assets/images/moz.png'
import { ToastNotification, loginError } from '../../../components/common/notification/ToastNotification';
import '../../../assets/styles/login.css';
import { defaultRoutes } from '../../../components/common/routes/default'


const formData = [
    {
        placeholder: 'Username',
        type: 'text',
        name: 'username',
        errorHelper: 'Username is required',
        id: 'username_id'
    }, {
        placeholder: 'Password',
        type: 'password',
        name: 'password',
        errorHelper: 'Password is required',
        id: 'password_id'
    }
]

const Login = () => {


    const [checked, setChecked] = useState(false);
    const { setAuth } = useContext(AuthContext);
    const [user, setUser] = useState({});
    const [error, setError] = useState(null);
    const history = useHistory();
    const { auth } = useContext(AuthContext)
    const [loading, setloading] = useState(false)

    if (auth) {
        history.push(defaultRoutes[8])
    }

    const checkBoxHandleChange = event => {
        setChecked(!checked)
    }

    const onUserChange = ({ target: { name, value } }) => {
        setUser({
            ...user,
            [name]: value
        })
    }

    const onSubmit = async (event) => {

        event.preventDefault();
        setloading(true)

        var config = {
            method: 'get',
            url: `${BASE_URL}/api/covid/auth/user`,
            headers: {
                'ENTRYPOINT': base64Enconder(user.username, user.password),
                Authorization: `Bearer ${process.env.REACT_APP_BASE_TOKEN}`,
            }
        };

        axios(config)
            .then(response => {
                //Guardando dados do usuario no cookie com duracao de 7 dias
                Cookies.set('user__traveller_portal', JSON.stringify(response.data.user), { expires: 7 })
 
                setUser({});
                setAuth(response.data.user)

                history.push(defaultRoutes[8])
                setloading(false)

            })
            .catch(error => {
                setError(error)
                setloading(false)
                loginError("Error to login, check your credentials!");
            });
    }


    return (
        <Row className='w-100 d-flex justify-content-center align-items-center m-0 p-0' style={{ height: '100vh' }}>


            <Container fluid className="main-content-container px-4 pb-4 col-lg-3">

                <div className="limiter">
                    <div className="container-login100">
                        <div className="p-t-50 p-b-90">


                            <Form onSubmit={onSubmit} className="login100-form validate-form flex-sb flex-w">
                                <div className="text-center col-lg-12">
                                    <img className="mb-1" src={mozambique} alt="SIVE" height="120" />
                                    <p className="mb-3">Ministério da Saúde</p>
                                    <p className="h6 mb-3 font-weight-bold ">Plataforma de Certificados de Vacinacao</p>
                                    <p className="mb-3" >Introduza os seus dados de acesso para continuar!</p>
                                </div>

                                {
                                    formData.map((data, index) => (
                                        <div className="wrap-input100 validate-input m-b-16" data-validate={data.errorHelper} type={data.type}>
                                            <input className="input100" name={data.name} key={index} required onChange={(e) => onUserChange(e)} placeholder={data.placeholder} type={data.type} />
                                            <span className="focus-input100"></span>
                                        </div>
                                    ))
                                }


                                <div className="flex-sb-m w-full p-t-3 p-b-24">
                                    <div className="contact100-form-checkbox">
                                        <input className="input-checkbox100" id="ckb1" type="checkbox" onChange={e => checkBoxHandleChange(e)} name="remember-me" />
                                        <label className="label-checkbox100" htmlFor="ckb1">
                                            Remember me
                                        </label>
                                    </div>

                                    <div>
                                        <a href="#" className="txt1">
                                            Forgot credentials?
                                        </a>
                                    </div>
                                </div>

                                <div className="container-login100-form-btn m-t-17">

                                    <Button type="submit" className="login100-form-btn" disabled={loading}  >
                                        {loading ?
                                            <>
                                                <span role="status" aria-hidden="true"></span>
                                                Loading ...
                                            </>
                                            :
                                            <>
                                                <span role="status" aria-hidden="true"></span>
                                                Log in
                                            </>

                                        }
                                    </Button>

                                    {(error) ?
                                        <ToastNotification />
                                        :
                                        null
                                    }



                                </div>



                                <div className="text-center col-lg-12 mt-5">
                                    <p>Powered by DHIS2 </p>

                                </div>


                            </Form>
                        </div>
                    </div>
                </div>


                <div id="dropDownSelect1"></div>



            </Container>
        </Row>
    )
}

export { Login }