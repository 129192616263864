import React from 'react';

const Table = ({ isFetching, tableColumns, tableData }) => {

    console.log(isFetching)

  return (
    <table className="table   mb-0">
      <thead className="bg-light">
        <tr>
          {
            tableColumns().map((column, index) => (
              <th style={{fontWeight:'500'}} scope="col" className="border-0">
                {column}
              </th>
            ))
          }
        </tr>
      </thead>
      <tbody className="w-100">
        {isFetching === false &&
          (tableData().length === 0 ? (
            <tr className="w-100">
              <div className="w-100">

              </div>
            </tr>
          ) : (
            tableData().map((row, index) => (
              <tr key={index}>
                {
                  row.map((cellData, index) => (
                    <td>{cellData}</td>
                  ))
                }
              </tr>
            ))
          ))}
      </tbody>
    </table>
  )
}

export default Table