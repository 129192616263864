import { defaultRoutes } from "../components/common";

export default function () {
  return [
   {
      title: "Pesquisar solicitação",
      to: defaultRoutes[8],
      htmlBefore: '<i class="material-icons">search</i>',
    },
    {
      title: "Certificados Requisitados",
      to: defaultRoutes[4],
      htmlBefore: '<i class="material-icons">info</i>',

    },
    {
      title: "Certificados Emitidos",
      to: defaultRoutes[5],
      htmlBefore: '<i class="material-icons">print</i>',
    }
  ];
}
