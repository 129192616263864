import React, { useEffect, useState } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import { useFetch } from "./hooks/useFetch";
import { SimpleBackdrop } from "./components/common/loader/Backdrop";
import { VariablesContext } from "./context/VariablesContext";
import { AuthContext } from "./context/AuthProvider";
import Cookies from 'js-cookie'


const App = () => {

  const { data, error, isFetching } = useFetch("api/covid/immunization/certificate/variables")
  const provinces = useFetch(`api/covid/immunization/certificate/solicitations/orgUnits?level=province`)
  const [auth, setAuth] = useState(null);

  const readCookies = () => {
    const user = JSON.parse(Cookies.get('user__traveller_portal') || null)
    if (user) {
      setAuth(user)
    }
  }

  useEffect(() => {
    readCookies()
  }, [])

  // if (isFetching || provinces.isFetching) {
  //   return (
  //     <SimpleBackdrop open={true} />
  //   )
  // }

  return (
    <Router>
      <Switch>
        <AuthContext.Provider
          value={{
            auth: auth,
            setAuth: setAuth
          }}
        >
          <VariablesContext.Provider value={{
            variables: data,
            provinces: provinces
          }}>
            <div className="h-100">
              {routes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={withTracker(props => {
                      return (
                        <route.layout {...props}>
                          <route.component {...props} />
                        </route.layout>
                      );
                    })}
                  />
                );
              })}
            </div>
          </VariablesContext.Provider>
        </AuthContext.Provider>
      </Switch>
    </Router>
  )
};

export { App }
