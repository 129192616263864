import React from "react";
import "./Alert.css";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Modal, ModalBody, ModalFooter } from "shards-react";

const Alert = ({ show, handleClose, message }) => {

  return (
    <Modal open={show} toggle={() => handleClose(false)}>
      <ModalBody className="m-body">
        <WarningRoundedIcon
          style={{ color: "#ff808b", fontSize: "4rem" }}
          className="mb-3"
        />
        <span className="oh-snap">{message}</span>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center m-footer">
        <button onClick={() => handleClose(false)}>VOLTAR</button>
      </ModalFooter>
    </Modal>
  );
};
export default Alert;