import React from 'react';
import { Col, Container, Row } from 'shards-react';
import { SmallStats } from '../../../components/common/chart/ SmallStats';
import { LineChart } from '../../../components/common/chart/LineChart';
import { PageTitle } from '../../../components/common/PageTitle';
import { smallStats } from '../linelists/smallStats';

const Dasboard = () => {

  return (
    <Container fluid className="main-content-container px-4">

      <Row noGutters className="page-header py-4">
        <PageTitle title="Total de certificados" className="text-sm-left mb-3" />
      </Row>

      <h5 className='text-muted'>Hoje</h5>
      <Row>
        {smallStats().map((stats, idx) => (
          <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
            <SmallStats
              id={`small-stats-${idx}`}
              variation="1"
              chartData={stats.datasets}
              chartLabels={stats.chartLabels}
              label={stats.label}
              value={stats.value}
              percentage={stats.percentage}
              increase={stats.increase}
              decrease={stats.decrease}
            />
          </Col>
        ))}
      </Row>

      <h5 className='text-muted'>Total</h5>
      <Row >
        {smallStats().map((stats, idx) => (
          <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
            <SmallStats
              id={`small-stats-${idx}`}
              variation="1"
              chartData={stats.datasets}
              chartLabels={stats.chartLabels}
              label={stats.label}
              value={stats.value}
              percentage={stats.percentage}
              increase={stats.increase}
              decrease={stats.decrease}
            />
          </Col>
        ))}
      </Row>

      <Row>
        <Col lg="12" md="12" sm="12" className="mb-4">
          <LineChart />
        </Col>
      </Row>
    </Container>
  )
}

export { Dasboard }