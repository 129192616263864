import React from "react";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Container,
  Col
} from "shards-react";

export default ({ position }) => (
  <Container >
    <Col>
      <h5 className={position}>
        Certificado de Vacinação contra Covid-19
      </h5>
    </Col>
  </Container>
);
