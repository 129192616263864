import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CircularLoader } from '../../../components/common/loader/CircularLoader';
import { useFetch } from '../../../hooks/useFetch';
import "../../../assets/styles/certificate.css";

function Certificate() {
    const { id } = useParams()

    let { data, error, isFetching } = useFetch(`api/loginmAction?trackedEntityInstance=${id}&certificateCheck=true`)

    if (isFetching) {
        return (
            <div className="spin-loader" >
                <CircularLoader />
            </div>
        )
    }

    return (
        <div style={{ backgroundColor: "#f0f3f5" }}>
            {data && <div dangerouslySetInnerHTML={{ __html: data }} />}
        </div>
    )
}

export default Certificate
