import { LoadingButton } from '@mui/lab';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { LinearLoader } from '../../../components/common/loader/LinearLoader';
import { PageTitle } from '../../../components/common/PageTitle';
import { Pagination } from '../../../components/common/pagination/Pagination';
import Table from '../../../components/common/table/Table';
import { AuthContext } from '../../../context/AuthProvider';
import { useFetch } from '../../../hooks/useFetch';
import { updateEvent } from './updateEventStatus';

const ReportByPrinted = () => {
  const [id, setId] = useState();
  const { auth } = useContext(AuthContext)
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data, isFetching } = useFetch(`api/covid/immunization/certificate/solicitations?status=printed&page=${currentPage}&pageSize=${rowsPerPage}`);



  const isLoading = (key) => id === key



  const tableColumns = () => [
    'Referencia', 'Nome do paciente', 'Apelido do paciente', 'Sexo', 'Telefone', ''
  ]

  const tableData = () => {
    const allData = [];
    if (isFetching === false)
      for (const print of data.solicitations) {
        console.log(print)
        allData.push([print.reference, print.patient.firstName, print.patient.surname, print.patient.gender, print.patient.telecom[0].phone,
        <Link target='_blank' to={`/certificate/print/${print.patient.teiId}/${auth.id}`}>IMPRIMIR</Link>,
          // <LoadingButton color='success' key={print.reference} onClick={() => updateEvent(print, 'AUTHENTICATED', setId)} loading={isLoading(print.id)} variant="outlined" size='small' >
          //   autenticar
          // </LoadingButton>
        ])
      }
    return allData
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Total de certificados impressos" className="text-sm-left mb-3" />
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
            </CardHeader>
            {isFetching && <LinearLoader />}
            <CardBody className="p-0 pb-3">
              <Table isFetching={isFetching} tableData={tableData} tableColumns={tableColumns} />
            </CardBody>
            {data && <Pagination totalPages={data} setCurrentPage={setCurrentPage} currentPage={currentPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />}
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export { ReportByPrinted }