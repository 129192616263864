import React, { useEffect } from 'react'
import { FormInput } from 'shards-react'

function Input(props) {
    return (
        <FormInput
            {...props}
        />
    )
}

export default Input