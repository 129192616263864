import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { CardHeader } from 'shards-react'
import GenericForm from '../../../components/common/form/GenericForm'

function TableValidation({ data = [], values, disabled, setvaluesDataElements, originValues }) {

    function setValues(ind, i, value) {
        let currentValues = [...values]
        currentValues[ind][i].value = value
        // console.log(currentValues[ind][i])

        originValues.immunizations[ind][currentValues[ind][i].attributeName] = value

        setvaluesDataElements(currentValues)
    }

    // console.log(originValues)

    return (
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    {data.length > 0 && data.map((x) => (
                        <TableCell padding="check" key={x.reference}>
                            {x.name}
                        </TableCell>
                    ))}
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>

                {values.map((rows, index) =>
                    <TableRow>
                        {rows.map((x, i) =>
                            <TableCell key={i}>
                                <GenericForm
                                    placeholder={x.name}
                                    onChange={(e) => { setValues(index, i, e.target.value) }}
                                    value={x.value}
                                    {...x}
                                    disabled={disabled}
                                />
                            </TableCell>
                        )}
                    </TableRow>
                )}
            </TableBody>
        </Table>
    )
}

export default TableValidation