import React, { useState } from "react";
import "../../../assets/styles/Slide.css";
import Background from "../../../assets/images/background.jpeg";
import MainMenuCards from "../../../components/common/cards/MainMenuCards";
import { Button } from "shards-react";


function Home(props) {
  const { t } = props;

  return (
    <section id="hero">

      <div className="hero-container">
        <div
          id="heroCarousel"
          className="carousel slide carousel-fade"
          data-ride="carousel"
        >
          <ol
            className="carousel-indicators"
            id="hero-carousel-indicators"
          ></ol>

          <div className="carousel-inner" role="listbox">
            <div className="carousel-item active">
              <div
                className="carousel-item active"
                style={{ backgroundImage: `url(${Background})` }}
              >
                <div className="carousel-container  allContent">
                  <div className="carousel-content col-lg-8  intro">
                    <h2 className="sliderText">
                      Bem vindo ao<span> Portal do Utente</span>
                    </h2>
                    <h5 className="sliderText">
                      Este serviço foi criado para flexibilizar o processo de solicitação
                      de Certificados de Vacinação contra Covid-19 em Moçambique.
                    </h5>
                    <br></br> <br></br>
                    {/* <MainMenuCards /> */}

                    <Button style={{ fontWeight: 20, fontSize: 20 }}>
                      Brevemente poderá efectuar a solicitação do certificado de vacinação
                    </Button>
                    <br></br> <br></br> <br></br>
                    <h4 className="sliderText">
                      <strong>O nosso maior valor é a vida!</strong>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
