import React from "react";
import { Nav } from "shards-react";
// import MainMenu from "./MainMenu";
import Notifications from "./Notifications";
import UserActions from "./UserActions";

export default () => (
  <Nav navbar className="border-left flex-row">
    <Notifications />
    {/* <MainMenu /> */}
    <UserActions />
  </Nav>
);
