import axios from 'axios';
import React, { useContext, useState } from 'react';
import { Button, Card, CardBody, CardFooter, Col, Container, InputGroup, InputGroupAddon, InputGroupText, Row } from 'shards-react';
import Input from '../../../components/common/form/Input';
import { CircularLoader } from '../../../components/common/loader/CircularLoader';
import Table from '../../../components/common/table/Table';
import { BASE_URL, requestHeader } from '../../../hooks/useFetch';
import { Link, Redirect } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { AuthContext } from '../../../context/AuthProvider';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Button as MaterialButton } from '@mui/material';
import Alert from '../../../components/common/alert/alertModal/Alert';



const SearchByReport = () => {

  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState('');
  const [data, setData] = useState(null);
  const [id, setId] = useState();
  const { auth } = useContext(AuthContext)


  const [idDocument, setIdDocument] = useState();
  const [birthDate, setBirthDate] = useState();
  const [openPopUp, setopenPopUp] = useState(false)
  const [popUrl, setpopUrl] = useState("")
  const [error, seterror] = useState(false)
  const [message, setmessage] = useState("")



  const onInputChange = (event) => {
    setInput(event.target.value)
  }

  const onBirthDateChange = (event) => {
    setBirthDate(event.target.value);
  };

  const onIdDocumentChange = (event) => {
    setIdDocument(event.target.value);
  };

  const tableColumns = () => [
    'Referencia', 'Nome do paciente', 'Apelido do paciente', 'Sexo', 'Telefone', 'Estado', data.solicitations.status === 'REQUESTED' && 'Detalhes'
  ]

  const onClick = async () => {

    const fetcher = () => {
      if (input)
        return `/api/covid/immunization/certificate/solicitations?reference=${input}`
      else if (idDocument && birthDate)
        return `/api/covid/immunization/certificate/patients/auth?nationalId=${idDocument}&birthDate=${birthDate}`
    }


    if (input !== '' || (idDocument && birthDate)) {
      setLoader(true)
      const data = await axios.get(`${BASE_URL}${fetcher()}`, requestHeader())
        .catch(err => {
          setOpen(true)
          setLoader(false)
        })
      setLoader(false)
      if (data && data.data.solicitations.length === 0) {
        setOpen(true)
      }
      setData(data.data)
    }
  }

  const postData = async (certificateId) => {

    setId(certificateId)
    const certificateData = await axios.get(`${BASE_URL}/api/covid/immunization/certificate/solicitations/${certificateId}`, requestHeader())
    certificateData.data.status = 'PRINTED'
    if (certificateData.status === 200) {
      await axios.put(`${BASE_URL}/api/covid/immunization/certificate/solicitations`, certificateData.data, requestHeader())
        .then(r => {
          setId('')
          if (r.status === 200) {

            const body = {
              "trackedEntityInstance": r.data.patient.teiId,
              "userId": auth.userId,
              "username": auth.userCredentials.username,
              "provider": "WEB_APP",
              "printDate": new Date().toISOString().substring(0, 10) + ' ' + new Date().toISOString().substring(11, 19),
              "location": certificateData.data.location,
              "locationName": null,
              "solicitationId": certificateData.data.id
            }

            axios.post(`${BASE_URL}/api/covid/immunization/certificate/print`, body, requestHeader())
              .catch(err => {
                console.log(err)
              })

            // const URL = `https://apps.hisplp.org/exam-printer-mz?teis=${r.data.patient.teiId}&user=${auth.id}`

            let URL = `/certificate/print/${r.data.patient.teiId}/${auth.id}`

            setpopUrl(URL)

            setopenPopUp(true)
            setData(null)
          }
        })
        .catch(e => {
          setId('')
          setmessage("Erro ao imprimir,tente novamente ou informe o utente que deve solicitar a segunda via")
          seterror(true)
        })
      setId('')
    }
    setmessage("Erro ao imprimir,tente novamente ou informe o utente que deve solicitar a segunda via")
    seterror(true)
  }

  const isLoading = (key) => id === key


  const tableData = () => {
    const allData = [];
    if (loader === false)
      for (const print of data.solicitations) {
        allData.push([print.reference, print.patient.firstName, print.patient.surname, print.patient.gender, print.patient.telecom[0].phone, print.status, print.status === 'REQUESTED' && <div>
          <LoadingButton color='success' key={print.reference} onClick={() => postData(print.id)} loading={isLoading(print.id)} variant="outlined" size='small' className='mr-1' >
            Imprimir
          </LoadingButton>
          <Link to={{
            pathname: `/covid/reports/request/${print.id}`,
            state: '/search'
          }}>
            <MaterialButton key={print.reference} disabled={loader} size='small' variant="outlined">
              Ver detalhes
            </MaterialButton>
          </Link>
        </div>])
      }
    return allData
  }

  if (open) {
    return <Alert message='Impossivel obter a resposta. Certifique-se que os dados da pesquisa estão corretos' show={open} handleClose={setOpen} />
  }


  if (openPopUp) {
    return <Redirect
      to={popUrl}
    />
  }

  if (error) {
    return <Alert
      handleClose={seterror}
      message={message}
      show={error}
    />
  }

  return (
    <Container className='p-4 d-flex justify-content-center' style={{ height: 'calc(100% - 120px)' }}>
      <Row className='w-100 d-flex justify-content-center'>
        <Col xs={11}>
          <InputGroup className='mb-3'>
            <Input type="search" placeholder="Referência do certificado" style={{ height: '50px' }} onChange={(event) => onInputChange(event)} />
          </InputGroup>

          <div className='w-100 d-flex justify-content-center mb-3'>OU</div>

          <InputGroup className='mb-3'>
            <Input type="text" placeholder="Numero de documento" style={{ height: '50px' }} onChange={(event) => onIdDocumentChange(event)} />
            <Input type="date" style={{ height: '50px' }} onChange={(event) => onBirthDateChange(event)} />
          </InputGroup>

          <InputGroupAddon className='mb-2' type="append" onClick={() => onClick()} style={{ cursor: 'pointer' }}>
            <InputGroupText>
              {
                loader === false ? (<i className="material-icons">search</i>) : (
                  <CircularLoader size={18} />
                )
              }
            </InputGroupText>
          </InputGroupAddon>


          {(loader === false && (data && data.solicitations.length > 0)) ?
            <Card small className="mb-4">
              <Table isFetching={loader} tableColumns={tableColumns} tableData={tableData} />
            </Card>
            :
            (open &&
              <Card className='mt-3'>
                <CardBody className="m-body">
                  <WarningRoundedIcon
                    style={{ color: "#ff808b", fontSize: "4rem" }}
                    className="mb-3"
                  />
                  <span className="oh-snap">Não existem dados para esta pesquisa</span>
                </CardBody>
                <CardFooter className="d-flex justify-content-center">
                  <Button theme="light" style={{ backgroundColor: '#ff808b', color: 'white' }} onClick={() => setOpen(false)}>ENTENDI</Button>
                </CardFooter>
              </Card>
            )
          }
        </Col>
      </Row>
    </Container>
  )
}

export { SearchByReport }