import React from 'react';
import NavabarPublic from '../components/layout/PublicNavibar/NavabarPublic';
import style from '../assets/styles/Application.module.css'

function Public({ children }) {
    return (
        <div className={style.application}>
            <NavabarPublic />
            <div style={{ marginTop: 56 }} />
            {children}
        </div>
    )
}

export default Public
