import React from "react";
import { Col, Container, Row } from "shards-react";
import NavabarPublic from "../components/layout/PublicNavibar/NavabarPublic";

const NoSession = ({ children }) => (
  <Container fluid style={{ overflow: 'hidden' }}>
    <NavabarPublic />
    <Row className='m-0 p-0'>
      <Col
        className="p-0 m-0"
        lg={{ size: 12, offset: 0 }}
        md={{ size: 12, offset: 0 }}
        sm="12"
        tag="main"
      >
        {children}
      </Col>
    </Row>
  </Container>
);

export default NoSession;
