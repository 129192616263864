import { Redirect } from "react-router-dom";
import React from "react";

import { defaultRoutes } from "./components/common";
import Public from "./layouts/Public";
import Home from "./views/public/home/Home";
import PreRegistro from "./views/public/prereg/PreRegistro";
import Renew from "./views/public/renew/Renew";
import DefaultLayout from "./layouts/Default";
import { Dasboard } from "./views/private/dashboard/Dasboard";
import { ReportByRequest } from "./views/private/linelists/ReportByRequest";
import { ReportByPrinted } from "./views/private/linelists/ReportByPrinted";
import { ReportByValidated } from "./views/private/linelists/ReportByValidated";
import { ReportByDelivered } from "./views/private/linelists/ReportByDelivered";
import { SearchByReport } from "./views/private/dataEntry/SearchByReport";
import FullWidth from "./layouts/FullWidth";
import { ValidateReport } from "./views/private/dataEntry/ValidateReport";
import { ReportByAuthenticated } from "./views/private/linelists/ReportByAuthenticated";
import { Confirmation } from "./views/public/prereg/Confirmation";
import { Login } from "./views/private/login/Login";
import NoSession from "./layouts/NoSession";
import { RequiredAuth } from "./auth/RequiredAuth";
import Certificate from "./views/certificate/print/Certificate";
import CertificateCheck from "./views/certificate/check/Certificate";



export default [
  {
    path: defaultRoutes[0],
    exact: true,
    layout: Public,
    component: Home
  },
  // {
  //   path: defaultRoutes[1],
  //   exact: true,
  //   layout: Public,
  //   component: PreRegistro
  // },
  // {
  //   path: defaultRoutes[9],
  //   exact: true,
  //   layout: Public,
  //   component: Renew
  // },
  {
    path: defaultRoutes[2],
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to={defaultRoutes[13]} />
  },
  {
    path: defaultRoutes[3],
    exact: true,
    layout: DefaultLayout,
    component: () => <RequiredAuth><Dasboard /></RequiredAuth>
  },
  {
    path: defaultRoutes[4],
    exact: true,
    layout: DefaultLayout,
    component: () => <RequiredAuth><ReportByRequest /></RequiredAuth>
  },
  {
    path: defaultRoutes[5],
    exact: true,
    layout: DefaultLayout,
    component: () => <RequiredAuth><ReportByPrinted /></RequiredAuth>
  },
  {
    path: defaultRoutes[6],
    exact: true,
    layout: DefaultLayout,
    component: () => <RequiredAuth><ReportByValidated /></RequiredAuth>
  },
  {
    path: defaultRoutes[7],
    exact: true,
    layout: DefaultLayout,
    component: () => <RequiredAuth><ReportByDelivered /></RequiredAuth>
  },
  {
    path: defaultRoutes[8],
    exact: true,
    layout: DefaultLayout,
    component: () => <RequiredAuth><SearchByReport /></RequiredAuth>
  },
  {
    path: defaultRoutes[10],
    exact: true,
    layout: FullWidth,
    component: () => <RequiredAuth><ValidateReport /></RequiredAuth>
  },
  {
    path: defaultRoutes[11],
    exact: true,
    layout: DefaultLayout,
    component: () => <RequiredAuth><ReportByAuthenticated /></RequiredAuth>
  },
  // {
  //   path: defaultRoutes[12],
  //   exact: true,
  //   layout: Public,
  //   component: Confirmation
  // },
   {
    path: defaultRoutes[13],
    exact: true,
    layout: NoSession,
    component: Login
  },
  // {
  //   path: defaultRoutes[15],
  //   exact: true,
  //   layout: Certificate,
  //   component: <></>
  // },
  {
    path: defaultRoutes[16],
    exact: true,
    layout: NoSession,
    component: CertificateCheck
  }
  // ,
  // {
  //   path:'*',
  //   layout:NoSession,
  //   component:()=><div className="d-flex justify-content-center align-items-center" style={{marginTop:'70px'}}>
  //     <h3>CONTENT NOT FOUND</h3>
  //   </div>
  // }

];
