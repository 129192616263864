import axios from "axios"
import { BASE_URL, requestHeader } from "../../../hooks/useFetch"

const updateEvent = async (event, status, setLoader, provider) => {

    let body = {}
    let endpoint = '/api/covid/immunization/certificate/solicitations';

    event.status = status;
    body = event

    // } else {
    //     body = {
    //         "trackedEntityInstance": event.patient.teiId,
    //         "userId": "GZ4h1peWMyU",
    //         "username": "sd",
    //         "provider": "WEB_APP",
    //         "printDate": new Date().toISOString().substring(0, 10) + ' ' + new Date().toISOString().substring(11, 19),
    //         "location": event.location,
    //         "locationName": null,
    //         "solicitationId": event.id
    //     }
    //     endpoint = '/api/covid/immunization/certificate/print'
    // }


    let updater = null;

    setLoader(event.id)
    if (provider === 'print')
        updater = await axios.post(`${BASE_URL}${endpoint}`, body, requestHeader())
    else
        updater = await axios.put(`${BASE_URL}${endpoint}`, body, requestHeader())
    console.log(updater)
    setLoader('')
}

export { updateEvent }