import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import Cookies from 'js-cookie'
import { AuthContext } from "../../../../context/AuthProvider";


const UserActions = () => {

  const [visible, setVisible] = useState(false);
  const {setAuth} = useContext(AuthContext)
  const {auth} = useContext(AuthContext)

  const toggleUserActions = () => {
    setVisible(!visible)
  }


  const logout = () => {
    Cookies.remove('user__traveller_portal')
    setAuth(null);
  }

  console.log(auth)

  return (
    <NavItem tag={Dropdown} caret toggle={toggleUserActions}>
      <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
        <img
          className="user-avatar rounded-circle mr-2"
          src={require("../../../../assets/images/avatars/2.jpg")}
          alt="User Avatar"
        />{" "}
        <span className="d-none d-md-inline-block">{auth && auth.userCredentials.username}</span>
      </DropdownToggle>
      <Collapse tag={DropdownMenu} right small open={visible}>
        <DropdownItem tag={Link} to="user-profile">
          <i className="material-icons">&#xE7FD;</i> Perfil
        </DropdownItem>

        <DropdownItem divider />
        <DropdownItem onClick={()=>logout()} tag={Link} to="/login" className="text-danger">
          <i className="material-icons text-danger">&#xE879;</i> Terminar sessão
        </DropdownItem>
      </Collapse>
    </NavItem>
  );
}

export default UserActions;