
export const defaultRoutes = [
    "/",
    "/request",
    "/covid",
    "/covid/dashboard",
    "/covid/reports/requested",
    "/covid/reports/printed",
    "/covid/reports/validated",
    "/covid/reports/delivered",
    "/covid/reports/search",
    "/renew/:action",
    "/covid/reports/request/:id",
    "/covid/reports/authenticated",
    "/prereg/confirmation/:reference",
    "/login",
    "/unauthorized",
    "/certificate/print/:tei/:user",
    "/certificate/:id"
]