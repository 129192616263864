import React, { createContext } from 'react';

const VariablesContext = createContext();
const VariablesProvider = ({ children }) => {
    return (
        <VariablesContext.Provider>
            {children}
        </VariablesContext.Provider>
    )
}
export { VariablesContext, VariablesProvider };