import React from 'react';
import TablePagination from '@mui/material/TablePagination';


const Pagination = ({ totalPages, setCurrentPage, currentPage, rowsPerPage, setRowsPerPage }) => {

    console.log(totalPages, currentPage, rowsPerPage)

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setCurrentPage(1);
    };

    return (
        <TablePagination
            component="div"
            count={totalPages.pager.total}
            page={currentPage === 0 || currentPage === 1 ? 0 : currentPage}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 20, 30, 50, 100]}
        />
    );
}

export { Pagination }