import { Button } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, FormSelect } from "shards-react";
import { LinearLoader } from '../../../components/common/loader/LinearLoader';
import { PageTitle } from '../../../components/common/PageTitle';
import { Pagination } from '../../../components/common/pagination/Pagination';
import Table from '../../../components/common/table/Table';
import { BASE_URL, requestHeader, useFetch } from '../../../hooks/useFetch';
import { Link, Redirect } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import axios from 'axios'
import { AuthContext } from '../../../context/AuthProvider';
import Alert from '../../../components/common/alert/alertModal/Alert';

const ReportByRequest = () => {

  const [id, setId] = useState();
  const { auth } = useContext(AuthContext)
  const [openPopUp, setopenPopUp] = useState(false)
  const [popUrl, setpopUrl] = useState("")
  const [error, seterror] = useState(false)
  const [message, setmessage] = useState("")
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data, isFetching } = useFetch(`api/covid/immunization/certificate/solicitations?status=requested&page=${currentPage}&pageSize=${rowsPerPage}`);


  const tableColumns = () => [
    'Referencia', 'Nome do paciente', 'Apelido do paciente', 'Sexo', 'Telefone', ''
  ]

  const postData = async (certificateId) => {
    setId(certificateId)
    const certificateData = await axios.get(`${BASE_URL}/api/covid/immunization/certificate/solicitations/${certificateId}`, requestHeader())
    console.log(certificateData)
    certificateData.data.status = 'PRINTED'
    if (certificateData.status === 200) {
      await axios.put(`${BASE_URL}/api/covid/immunization/certificate/solicitations`, certificateData.data, requestHeader())
        .then(r => {
          setId('')
          if (r.status === 200) {

            const body = {
              "trackedEntityInstance": r.data.patient.teiId,
              "userId": auth.id,
              "username": auth.userCredentials.username,
              "provider": "WEB_APP",
              "printDate": new Date().toISOString().substring(0, 10) + ' ' + new Date().toISOString().substring(11, 19),
              "location": certificateData.data.location,
              "locationName": null,
              "solicitationId": certificateData.data.id
            }

            axios.post(`${BASE_URL}/api/covid/immunization/certificate/print`, body, requestHeader())
              .catch(err => {
                console.log(err)
              })

            let URL = `/certificate/print/${r.data.patient.teiId}/${auth.id}`

            setpopUrl(URL)

            setopenPopUp(true)

          }
        })
        .catch(e => {
          setId('')
          setmessage("Erro ao imprimir,tente novamente ou informe o utente que deve solicitar a segunda via")
          seterror(true)
        })
      return
    }
    setmessage("Erro ao imprimir,tente novamente ou informe o utente que deve solicitar a segunda via")
    seterror(true)
  }


  const isLoading = (key) => id === key

  const tableData = () => {
    const allData = [];
    if (isFetching === false)
      for (const print of data.solicitations) {
        // console.log(print)
        allData.push([print.reference, print.patient.firstName, print.patient.surname, print.patient.gender, print.patient.telecom[0].phone, <div>
          <LoadingButton color='success' key={print.reference} onClick={() => postData(print.id)} loading={isLoading(print.id)} variant="outlined" size='small' className='mr-1' >
            Imprimir
          </LoadingButton>
          <Link to={{
            pathname: `/covid/reports/request/${print.id}`,
            state: '/requested'
          }}>
            <Button key={print.reference} disabled={isLoading(print.id)} size='small' variant="outlined">
              Ver detalhes
            </Button>
          </Link>
        </div>])
      }
    return allData
  }

  if (openPopUp) {
    return <Redirect
      to={popUrl}
    />
  }

  if (error) {
    return <Alert
      handleClose={seterror}
      message={message}
      show={error}
    />
  }

  return (
    <Container fluid className="main-content-container px-4">

      <Row noGutters className="page-header py-4">
        <PageTitle title="Total de certificados requisitados" className="text-sm-left mb-3" />
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
            </CardHeader>
            {isFetching && <LinearLoader />}
            <CardBody className="p-0 pb-3">
              <Table isFetching={isFetching} tableData={tableData} tableColumns={tableColumns} />
            </CardBody>
            <Container className="w-100 d-flex justify-content-end" >
              {data && <Pagination totalPages={data} setCurrentPage={setCurrentPage} currentPage={currentPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />}
            </Container>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export { ReportByRequest }