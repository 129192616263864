import React from 'react';
import ReactDOM from 'react-dom';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css"; 
import "primeicons/primeicons.css";                                //icons


import './index.css'



import { App } from './App';

ReactDOM.render(<App />, document.getElementById('root'));

