import React from "react";
import { Card, Carddescription, Col, Container, Row } from "shards-react";
import "../../../assets/styles/home.css";
import { Link, withRouter } from "react-router-dom";
import newD from "../../../assets/images/newD.png";
import update from "../../../assets/images/update.png";
import status from "../../../assets/images/status.png";
import { defaultRoutes } from "..";

const PostsListTwo = [
  {
    icon: newD,
    title: "Solicitar Certificado",
    description:
      "Selecione esta opção se já foi totalmente vacinado, mas ainda não teve acesso ao Certificado de Vacinação.",
    route: defaultRoutes[1],
  },
  {
    icon: update,
    title: "Solicitar a segunda via",
    description:
      "Caso já tenha tido acesso a um certificado de vacinação, selecione esta opção para solicitar a 2ª via do certificado.",
    route: 'renew/secondway',
  },
  {
    icon: status,
    title: "Meu certificado",
    description:
      "Se já tem um certificado ou está com uma solicitação pendente, selecione esta opção para verificar o estado.",
    route: 'renew/checkstatus',
  },
];

const MainMenuCards = withRouter(({ history }) => {
  return (
    <Row className="card-h">
      {PostsListTwo.map((post, idx) => (
        <div class="col-md-3 mb-3">
          <div class="card-sl">
            <div class="card-image">
              <img src={post.icon} width={80} />
            </div>

            <div class="card-heading">{post.title}</div>
            <div class="card-text">{post.description}</div>

            <button disabled className="card-button" to={post.route}>
              Continuar
            </button>
          </div>
        </div>
      ))}
    </Row>
  );
});

export default MainMenuCards;
