import React from 'react'
import CheckBox from './CheckBox';
import FormSelect from './FormSelect';
import Input from "./Input";
import Swicth from './Swicth';
import LongTextInput from './LongTextInput';

function GenericForm(props) {
    
    const { valueType } = props;

    switch (valueType) {
        case "TEXT":
        case "EMAIL":
        case "PHONE_NUMBER":
            return (
                <Input {...props} />
            );
            
        case "DATE":
        case "AGE":
            return (
                <Input type="date" {...props} />
            )

        case "BOOLEAN":
            return (
                <Swicth {...props} />
            )

        case "NUMBER":
        case "INTEGER_ZERO_OR_POSITIVE":
            return (
                <Input type="number" {...props} />
            )

        case "TRUE_ONLY":
            return (
                <CheckBox {...props} />
            )

        case "LIST":
            return (
                <FormSelect {...props} />
            )
        case "LONG_TEXT":
            return (
                <LongTextInput {...props} />
            )

        default:
            return null
    }
}

export default GenericForm