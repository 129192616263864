import { IconButton } from '@mui/material';
import React from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody} from "shards-react";
import { LinearLoader } from '../../../components/common/loader/LinearLoader';
import { PageTitle } from '../../../components/common/PageTitle';
import { Pagination } from '../../../components/common/pagination/Pagination';
import Table from '../../../components/common/table/Table';
import { useFetch } from '../../../hooks/useFetch';

const ReportByDelivered = () => {
  const { data, isFetching } = useFetch(`api/covid/immunization/certificate/solicitations?status=delivered`);

  const tableColumns = () => [
    'Referencia', 'Nome do paciente', 'Apelido do paciente', 'Sexo', 'Telefone'
  ]

  console.log(data)

  const tableData = () => {
    const allData = [];
    if (isFetching === false)
      for (const print of data.solicitations) {
        allData.push([print.reference, print.patient.firstName, print.patient.surname, print.patient.gender, print.patient.telecom[0].phone,<IconButton aria-label="delete"></IconButton>])
      }
    return allData
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Total de certificados entregues" className="text-sm-left mb-3" />
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Tickets by status</h6>
            </CardHeader>
            {isFetching && <LinearLoader />}
            <CardBody className="p-0 pb-3">
              <Table isFetching={isFetching} tableData={tableData} tableColumns={tableColumns} />
            </CardBody>
            <Container className="w-100 d-flex justify-content-end" >
              <Pagination />
            </Container>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export { ReportByDelivered }