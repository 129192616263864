import axios from 'axios';
import React, { useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Container } from 'shards-react'
import { BASE_URL, requestHeader } from '../../../hooks/useFetch';
import { LinearLoader } from '../loader/LinearLoader';
import { body } from './jsonForm';
import { Accordion, AccordionTab } from 'primereact/accordion';
import '../../../assets/styles/ConfirmData.css';
import { useHistory } from 'react-router-dom'
import Alert from "../../../components/common/alert/alertModal/Alert";
import { characters } from 'shortid/lib/alphabet';



const options = [
  {
    "code": "DOSE1",
    "displayName": "1ra dose"
  },
  {
    "code": "DOSE2",
    "displayName": "2da dose"
  },
  {
    "code": "DOSE3",
    "displayName": "3ra dose"
  },
  {
    "code": "BOOSTER",
    "displayName": "Dose de reforço"
  }
]

const ConfirmDataCard = (props) => {

  const { data, vacines, images } = props
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('')


  const vacineArray = (Object.keys(vacines).length / 3)
  const history = useHistory();

  const vacinesBody = [];

  for (let i = 0; i < vacineArray; i++) {
    vacinesBody.push(
      {
        "managingOrganization": data['local'].split('/')[1].split('&')[0],
        "occurence": new Date(vacines[i + 'kezq3AF23vB']).toISOString().substring(0, 23),
        "manufacturer": null,
        "vaccine": vacines[i + 'bbnyNYD1wgS'],
        "batchNumber": vacines[i + 'Yp1F4txx8tm'],
        "dose": i === 3 ? 'BOOSTER' : `DOSE${i + 1}`
      })
  }

  const postForm = async () => {
    setLoader(true)
    const postData = await axios.post(`${BASE_URL}/api/covid/immunization/certificate/solicitations`, body(data, vacinesBody), requestHeader())
      .catch(err => {
        setMessage(err.response.data.message)
        setOpen(true)
        setLoader(false)
      })

    if (postData && postData.data.status === 201) {
      let formData = new FormData();

      for (const image of images) {
        formData.append("documents[]", image, "[PROXY]")
      }

      formData.append("solicitationReference", postData.data.reference)
      const postFile = await axios.post(`${BASE_URL}/api/covid/immunization/certificate/solicitations/upload`, formData, requestHeader())
        .catch(err => {
          setMessage('Impossivel fazer upload dos documentos.')
          setOpen(true)
          setLoader(false)
        })
      setLoader(false)
      if (postFile.data.status === 200) {
        history.push({
          pathname: `./prereg/confirmation/${postData.data.reference}`,
          state: {
            detail: {
              patient: data,
              search: postData.data.reference
            }
          }
        })
      }
    }
  }

  if (open) {
    return <Alert message={message} show={open} handleClose={setOpen} />
  }

  const getVacineName = (vacine) => {
    const name = options.filter(element => {
      return element.code === vacine['dose']
    })[0].displayName

    return name
  }

  console.log(data, vacinesBody)

  return (
    <Container>
      <div>
        <div className="row m-auto">
          <div className="col-md-12 order-md-2 mb-10">
            <ul className="list-group mb-3 p-2">
              {loader === true && <LinearLoader />}
              <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div className="mt-2">
                  <h6 style={{ fontSize: "14px", fontWeight: 550, fontFamily: "inherit", lineHeight: "1.2" }} className="my-0">Nº do Passaporte/BI</h6>
                  <span style={{ fontSize: "11px" }}>ID Number</span>
                </div>
                <span style={{ fontSize: "13px" }} className="text-muted mt-2">{data['Ewi7FUfcHAD']}</span>
              </li>

              <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div className="mt-2">
                  <h6 style={{ fontSize: "14px", fontWeight: 550, fontFamily: "inherit", lineHeight: "1.2" }} className="my-0">Nome Completo</h6>
                  <span style={{ fontSize: "11px" }}>Full name</span>
                </div>
                <span style={{ fontSize: "13px" }} className="text-muted mt-2">{data['sB1IHYu2xQT']} {data['ENRjVGxVL6l']}</span>
              </li>

              <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div className="mt-2">
                  <h6 style={{ fontSize: "14px", fontWeight: 550, fontFamily: "inherit", lineHeight: "1.2" }} className="my-0">Data de Nascimento</h6>
                  <span style={{ fontSize: "11px" }}>Birth Date</span>
                </div>
                <span style={{ fontSize: "13px" }} className="text-muted mt-2">{data['mAWcalQYYyk']}</span>
              </li>

              <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div className="mt-2">
                  <h6 style={{ fontSize: "14px", fontWeight: 550, fontFamily: "inherit", lineHeight: "1.2" }} className="my-0">Sexo</h6>
                  <span style={{ fontSize: "11px" }}>Sex</span>
                </div>
                <span style={{ fontSize: "13px" }} className="text-muted mt-2">{data['CklPZdOd6H1'] === 'Male' ? 'Masculino' : 'Feminino'}</span>
              </li>
            </ul>
            <br />
            {vacinesBody.map((vacine, index) =>
              <>
                <h4 className="d-flex justify-content-between align-items-center mb-2  ps-2">
                  <span style={{ fontSize: "19px", fontWeight: 550, fontFamily: "inherit", lineHeight: "1.2" }} className="text-muted">{`Vacinação ${(1 + index)}`}</span>
                </h4>
                <ul className="list-group mb-3 p-2">
                  <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div className="mt-2">
                      <h6 style={{ fontSize: "14px", fontWeight: 550, fontFamily: "inherit", lineHeight: "1.2" }} className="my-0">Data de Vacinação</h6>
                      <span style={{ fontSize: "11px" }}>Vaccination Date</span>
                    </div>
                    <span style={{ fontSize: "13px" }} className="text-muted mt-2">{vacine.occurence.substring(0, 10)}</span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div className="mt-2">
                      <h6 style={{ fontSize: "14px", fontWeight: 550, fontFamily: "inherit", lineHeight: "1.2" }} className="my-0">Nome da Vacina</h6>
                      <span style={{ fontSize: "11px" }}>Vaccine</span>
                    </div>
                    <span style={{ fontSize: "13px" }} className="text-muted mt-2">{vacine['vaccine']}</span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div className="mt-2">
                      <h6 style={{ fontSize: "14px", fontWeight: 550, fontFamily: "inherit", lineHeight: "1.2" }} className="my-0">Dose</h6>
                      <span style={{ fontSize: "11px" }}>Dose</span>
                    </div>
                    <span style={{ fontSize: "13px" }} className="text-muted mt-2">{getVacineName(vacine)}</span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div className="mt-2">
                      <h6 style={{ fontSize: "14px", fontWeight: 550, fontFamily: "inherit", lineHeight: "1.2" }} className="my-0">Lote</h6>
                      <span style={{ fontSize: "11px" }}>Batch</span>
                    </div>
                    <span style={{ fontSize: "13px" }} className="text-muted mt-2">{vacine['batchNumber']}</span>
                  </li>

                </ul>
              </>
            )}
            <br />
            <ul className="list-group mb-3 p-2">
              <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div className="mt-2">
                  <h6 style={{ fontSize: "14px", fontWeight: 550, fontFamily: "inherit", lineHeight: "1.2" }} className="my-0">Local de Levantamento do Certificado</h6>
                  <span style={{ fontSize: "11px" }}>Certificate Pick-up Location</span>
                </div>
                <span style={{ fontSize: "13px" }} className="text-muted mt-2">{data['local'].split('&')[1]}</span>
              </li>
            </ul>
          </div>
        </div>
      </div >

      <div className='d-flex justify-content-end mb-4 p-2'>
        <Button theme="secondary" disabled={loader} onClick={() => props.changeStepper(0)} className='mr-2'>Voltar ao inicio do formulario</Button>
        <Button disabled={loader} onClick={() => postForm()}>Submeter</Button>
      </div>
    </Container>
  )
}

export { ConfirmDataCard }