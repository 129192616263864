import React, { useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody} from "shards-react";
import { LinearLoader } from '../../../components/common/loader/LinearLoader';
import { PageTitle } from '../../../components/common/PageTitle';
import { Pagination } from '../../../components/common/pagination/Pagination';
import Table from '../../../components/common/table/Table';
import { useFetch } from '../../../hooks/useFetch';
import { updateEvent } from './updateEventStatus';
import { LoadingButton } from '@mui/lab';

const ReportByValidated = () => {
  const { data, isFetching } = useFetch(`api/covid/immunization/certificate/solicitations?status=validated`);
  const[loader, setLoader] = useState();

  const tableColumns = () => [
    'Referencia', 'Nome do paciente', 'Apelido do paciente', 'Sexo', 'Telefone', ''
  ]

  const isLoading = (key) => loader === key


  const tableData = () => {
    const allData = [];
    if (isFetching === false)
      for (const print of data.solicitations) {
        allData.push([print.reference, print.patient.firstName, print.patient.surname, print.patient.gender, print.patient.telecom[0].phone,<LoadingButton color='success' key={print.reference}  loading={isLoading(print.id)} variant="outlined" size='small' onClick={()=>updateEvent(print,'PRINTED',setLoader,'print')} >emitir</LoadingButton>])
      }
    return allData
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Total de certificados validados" className="text-sm-left mb-3" />
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Tickets by status</h6>
            </CardHeader>
            {isFetching && <LinearLoader />}
            <CardBody className="p-0 pb-3">
              <Table isFetching={isFetching} tableData={tableData} tableColumns={tableColumns} />
            </CardBody>
            <Container className="w-100 d-flex justify-content-end" >
              <Pagination />
            </Container>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
export { ReportByValidated }