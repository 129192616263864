import axios from 'axios'
import React, { useState, useEffect, useContext } from 'react'
import { Stepper } from '../../../components/common'
import { ConfirmDataCard } from '../../../components/common/confirmData/ConfirmDataCard'
import { VariablesContext } from '../../../context/VariablesContext'
import { BASE_URL, requestHeader } from '../../../hooks/useFetch'
import FormComponent from './FormComponent'

const stepper = [
    {
        active: true,
        name: "Dados Pessoais",
        reacheable: true
    },
    {
        active: false,
        name: "Vacinas Administradas",
        reacheable: false
    },
    {
        active: false,
        name: "Local de Levantamento",
        reacheable: false
    },
    {
        active: false,
        name: "Confirmação",
        reacheable: false
    },
]


function PreRegistro() {

    const [steppers, setSteppers] = useState(stepper)
    const [variabeles, setvariabeles] = useState([])
    const [position, setposition] = useState(0)
    const { variables, provinces } = useContext(VariablesContext)
    const [formInputs, setFormInputs] = useState({});
    const [vacines, setVacines] = useState({});
    const [countDoses, setcountDoses] = useState([])
    const [provinceId, setProvinceId] = useState();
    const [localData, setLocalData] = useState(null);
    const [selectedImage, setSelectedImage] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);
    const [value, setValue] = useState(1);



    const fetchLocal = async () => {
        if (provinceId) {
            const data = await axios.get(`${BASE_URL}/api/covid/immunization/certificate/locations?province=${provinceId}`, requestHeader())
            setLocalData(data)
        }
    }

    useEffect(() => {
        getVariables()
    }, [variables, steppers, localData])

    useEffect(() => {
        fetchLocal()
    }, [provinceId])

    function getVariables() {
        if (position === 0) {
            if (variables && variables.hasOwnProperty("programs")) {
                let variab = []
                for (const attr of variables.programs[0].programTrackedEntityAttributes || []) {
                    if (attr.hasOwnProperty("options")) {
                        variab.push({ name: attr.name, id: attr.id, type: attr.valueType, options: attr.options, valueType: "LIST" })
                    } else
                        variab.push({ name: attr.name, id: attr.id, type: attr.valueType === 'AGE' ? 'date' : attr.valueType, valueType: attr.valueType })
                }
                setvariabeles(variab)
            }
        } else if (position === 1) {
            if (variables && variables.hasOwnProperty("programs")) {
                let variab = []
                for (const dataElement of variables.programs[0].programStages[0].programStageDataElements) {
                    if (dataElement.id !== "LUIsbsm3okG")
                        if (dataElement.hasOwnProperty("options")) {
                            variab.push({ name: dataElement.name, reference: dataElement.id, type: dataElement.valueType, valueType: "LIST", options: dataElement.options })
                        } else
                            variab.push({ name: dataElement.name, reference: dataElement.id, type: dataElement.valueType, valueType: dataElement.valueType })
                }
                setvariabeles(variab)
            }
        } else if (position === 2) {

            const options = [];


            if (localData) {
                for (const local of localData.data.data) {
                    options.push({
                        district: local.district,
                        id: local.location + '/' + local.orgUnit + '&' + local.address,
                        idd: local.id,
                        name: local.locationName,
                        province: local.province
                    })
                }
            }

            let variab = [
                {
                    name: "Provincia",
                    type: "select",
                    id: 'province',
                    valueType: "LIST",
                    options: provinces.data.organisationUnits

                }
            ]

            if (localData) {
                variab.push(
                    {
                        name: "Local de levantmento",
                        type: "select",
                        id: 'local',
                        disabled: localData === null,
                        valueType: "LIST",
                        options: options,
                        helperText: 'Av Eduardo Mondlane'
                    }
                )
            }

            setvariabeles(variab)
        } else {
            let variab = []
            setvariabeles(variab)
        }
    }

    const changeStepper = (i) => {
        const stepper = [...steppers]
        for (const current of stepper) {
            current.active = false
        }
        stepper[i].active = true
        stepper[i].reacheable = true
        setposition(i)
        setSteppers(stepper)
    }
    return (
        <div >
            <Stepper steppers={steppers} setSteppers={setSteppers} setposition={setposition} changeStepper={changeStepper} />

            {
                position !== 3 ?
                    variabeles.length > 0 &&
                    <FormComponent value={value} setValue={setValue} selectedImage={selectedImage} setSelectedImage={setSelectedImage} setImageUrls={setImageUrls} imageUrls={imageUrls} vacines={vacines} localData={localData} setVacines={setVacines} setProvinceId={setProvinceId} title='Dados Pessoais' countDoses={countDoses} setcountDoses={setcountDoses} formInputs={formInputs} setFormInputs={setFormInputs} steppers={steppers} data={variabeles} position={position} setposition={setposition} setSteppers={setSteppers} changeStepper={changeStepper} />
                    : (
                        <ConfirmDataCard changeStepper={changeStepper} data={formInputs} vacines={vacines} images={selectedImage} imagesUrl={imageUrls} />
                    )
            }
        </div>
    )
}

export default PreRegistro