import React from 'react'
import { FormSelect } from 'shards-react'

function FormSelects(props) {

    // console.log(props)

    return (
        <FormSelect id={props.id} {...props}>
            <option value="">Selecione {props.placeholder}</option>
            {props.hasOwnProperty("options") && props.options.length > 0 && props.options.map(x =>
                <option value={(props.reference === 'bbnyNYD1wgS' || props.reference === 'LUIsbsm3okG') ? x.code : x.id} id={x.code}>{x.name}</option>
            )}
        </FormSelect>
    )
}

export default FormSelects