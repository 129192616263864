import React from 'react'
import { Col, Form, ListGroup, ListGroupItem, Row } from 'shards-react'
import GenericForm from '../../../components/common/form/GenericForm'

function FormValidation({ data, disabled, setallVariables, originValues }) {

    function setValues(value, i) {
        let currentData = [...data]
        currentData[i].value = value

        if (currentData[i].attributeName.split(".").length > 1) {
            originValues[currentData[i].attributeName.split(".")[0]][0][currentData[i].attributeName.split(".")[1]] = value
        } else {
            originValues[currentData[i].attributeName] = value
        }

        setallVariables(currentData)
    }

    return (
        <ListGroup flush>
            <ListGroupItem className="p-3">
                <Row>
                    <Col>
                        <Form>
                            <Row form>
                                {data.length > 0 && data.map((x, i) =>
                                    <Col md="12" className="form-group" key={x.id} >
                                        <label htmlFor={x.id}>{x.name}</label>
                                        <GenericForm
                                            placeholder={x.name}
                                            onChange={(e) => { setValues(e.target.value, i) }}
                                            value={x.value}
                                            {...x}
                                            disabled={disabled}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </ListGroupItem>
        </ListGroup>
    )
}

export default FormValidation