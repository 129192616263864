import axios from "axios";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "shards-react";
import { BASE_URL, requestHeader } from "../../../hooks/useFetch";
import Background from "../../../assets/images/background.jpeg";
import Input from "../../../components/common/form/Input";
import { CircularLoader } from "../../../components/common/loader/CircularLoader";
import { useParams } from 'react-router-dom'
import FormRenew from "./FormRenew";
import '../../../assets/styles/Renew.css';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';


function Renew() {
  const [reference, setReference] = useState();
  const [idDocument, setIdDocument] = useState();
  const [birthDate, setBirthDate] = useState();
  const [certificate, setCertificate] = useState();
  const [loader, setLoader] = useState(false)
  const { action } = useParams();
  const [open, setOpen] = useState(false);

  const onChange = (event) => {
    setReference(event.target.value);
  };

  const onBirthDateChange = (event) => {
    setBirthDate(event.target.value);
  };

  const onIdDocumentChange = (event) => {
    setIdDocument(event.target.value);
  };

  const limpar = () => {
    setCertificate(null)
    setReference('')
  }


  const fetchData = async () => {

    const fetcher = () => {
      if (reference)
        return `/api/covid/immunization/certificate/solicitations/${reference}`
      else if (idDocument && birthDate)
        return `/api/covid/immunization/certificate/patients/auth?nationalId=${idDocument}&birthDate=${birthDate}`
    }

    if (reference || (idDocument && birthDate)) {
      setLoader(true)
      await axios.get(`${BASE_URL}${fetcher()}`, requestHeader())
        .then(resp => {
          if (resp.data.solicitations) {
            if (resp.data.solicitations.length === 0) {
              setOpen(true)
            } else {
              setOpen(false)
              setCertificate(resp.data.solicitations[0]);
            }
          } else {
            setCertificate(resp.data);
            setOpen(false)
          }
        })
        .catch(err => {
          setOpen(true)
        })
      setLoader(false)
    }
  };

  console.log(certificate)


  const color = (status) => {
    if (status === 'REQUESTED') return 'red'
    if (status === 'VALIDATED') return 'green'
    if (status === 'PRINTED') return 'blue'
  }

  const publicStatus = (status) => {
    if (status === 'REQUESTED') return 'AGUARDA PAGAMENTO'
    if (status === 'VALIDATED' || 'PRINTED') return 'AGUARDA AUTENTICAÇÃO'
    if (status === 'AUTHENTICATED') return 'PRONTO PARA O LEVANTAMENTO'
  }

  return (
    <Container fluid className="main-content-container p-0 renew">
      {
        (certificate && action === 'secondway') ? (
          <div className="w-100 pt-5 h-100 justify-content-center align-items-center">
            <FormRenew personalData={certificate} />
          </div>
        ) : (
          <section id="hero">
            <div className="hero-container">
              <div
                id="heroCarousel"
                className="carousel slide carousel-fade"
                data-ride="carousel"
              >
                <ol
                  className="carousel-indicators"
                  id="hero-carousel-indicators"
                ></ol>

                <div className="carousel-inner" role="listbox">
                  <div className="carousel-item active">
                    <div
                      className="carousel-item active"
                      style={{ backgroundImage: `url(${Background})` }}
                    >
                      <div className="carousel-container  allContent">
                        <div className="carousel-content col-lg-8  intro">
                          <Container className="p-0">
                            <Row className="w-100 d-flex justify-content-center">
                              <Col className=" col-sm-12 col-12">
                                <Form>
                                  <InputGroup className='mb-3'>
                                    <Input
                                      className="search-field text-center btn-lg"
                                      type="search"
                                      maxlength="20"
                                      required
                                      value={reference}
                                      onChange={(event) => onChange(event)}
                                      size="20"
                                      placeholder="INTRODUZA A REFERÊNCIA DA SOLICITAÇÃO"
                                    />
                                  </InputGroup>

                                  <div className="mb-3"><h5>OU</h5></div>

                                  <InputGroup className='idDoc'>
                                    <div className="idDoc-container w-50" style={{ display: 'block', textAlign: 'start' }}>
                                      <span>Número de documento de identificação</span>
                                      <Input
                                        className="search-field text-center btn-lg"
                                        type="text"
                                        required
                                        onChange={(event) => onIdDocumentChange(event)}
                                        value={idDocument}
                                        placeholder="NUMERO DE DOCUMENTO"
                                      />
                                    </div>

                                    <div className="birth-date-container w-50" style={{ display: 'block', textAlign: 'start' }}>
                                      <span>Data de nascimento</span>
                                      <Input
                                        className="search-field text-center btn-lg"
                                        type="date"
                                        onChange={(event) => onBirthDateChange(event)}
                                        value={birthDate}
                                        required
                                      />
                                    </div>
                                  </InputGroup>

                                  <Button className='mt-3' disabled={loader} onClick={() => fetchData()}>
                                    {
                                      loader === true ? <CircularLoader /> : 'PESQUISAR'
                                    }
                                  </Button>
                                </Form>
                                {
                                  (certificate) ? (
                                    <Card small className="my-4 px-5 " >
                                      <CardHeader>
                                        <Button onClick={() => limpar()}>Nova pesquisa</Button>
                                      </CardHeader>
                                      <CardBody>

                                        <div className="container justify-content-center">
                                          <div className="col-md-12 d-flex">
                                            <div className="col-md-12 col-12">
                                              <div className="row mb-4">
                                                <div className="col-md-6 text-right">
                                                  <strong>Nome do utente:</strong>
                                                </div>
                                                <div className="col-md-6 text-secondary text-left">
                                                  <h7 className="mb-0">{reference ? (certificate.patient['firstName'] + ' ' + certificate.patient['surname']) : (
                                                    certificate.patient['firstName'] + ' ' + certificate.patient['surname']
                                                  )}</h7>
                                                </div>
                                              </div>
                                              <div className="row mb-4">
                                                <div className="col-md-6 text-right">
                                                  <strong>Data da solcitiação:</strong>
                                                </div>
                                                <div className="col-md-6 text-secondary text-left">
                                                  <h7 className="mb-0">{reference ? certificate.created_at : certificate.created_at}</h7>
                                                </div>
                                              </div>
                                              <div className="row mb-4">
                                                <div className="col-md-6 text-right">
                                                  <strong>Data da atualização:</strong>
                                                </div>
                                                <div className="col-md-6 text-secondary text-left">
                                                  <h7 className="mb-0">{reference ? certificate.updated_at : certificate.updated_at}</h7>
                                                </div>
                                              </div>



                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 text-right">
                                              <h4><strong>ESTADO:</strong></h4>
                                            </div>
                                            <div className="col-md-6 text-secondary text-left">
                                              <h4 className="mb-0"><span style={{ color: color(reference ? certificate.status : certificate.status) }}><strong>{publicStatus(reference ? certificate.status : certificate.status)}</strong></span></h4>
                                            </div>
                                          </div>
                                        </div>

                                      </CardBody>
                                    </Card>
                                  ) : (
                                    (open) &&
                                    <Card className='mt-3'>
                                      <CardBody className="m-body">
                                        <WarningRoundedIcon
                                          style={{ color: "#ff808b", fontSize: "4rem" }}
                                          className="mb-3"
                                        />
                                        <span className="oh-snap">Não existem dados para esta pesquisa</span>
                                      </CardBody>
                                      <CardFooter className="d-flex justify-content-center">
                                        <Button theme="light" style={{ backgroundColor: '#ff808b', color: 'white' }} onClick={() => setOpen(false)}>ENTENDI</Button>
                                      </CardFooter>
                                    </Card>
                                  )
                                }
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      }
    </Container>
  );
}

export default Renew;
